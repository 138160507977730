import '../../styles/pages/spring-summer-2023/titan-in-the-outfield.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2023-stories/more-stories"
import Vimeo from '@u-wave/react-vimeo';

import socialBanner from "../../images/spring-summer-2023/social-banners/titan-in-the-outfield.jpg";
import Hero from "../../images/spring-summer-2023/titan-in-the-outfield/hero.jpg";
import kelsie1 from '../../images/spring-summer-2023/titan-in-the-outfield/kelsie-1.jpg';
import kelsie2 from '../../images/spring-summer-2023/titan-in-the-outfield/kelsie-2.jpg';
import kelsie3 from '../../images/spring-summer-2023/titan-in-the-outfield/kelsie-3.jpg';
import kelsie4 from '../../images/spring-summer-2023/titan-in-the-outfield/kelsie-4.jpg';
import kelsie5 from '../../images/spring-summer-2023/titan-in-the-outfield/kelsie-5.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Alumna Kelsie Whitmore to be first woman to play in MLB';
    var pageDesc = 'Former Titan softball player Kelsie Whitmore knew there would be a first woman to play Major League Baseball. She’s on the road to achieving that milestone.';
    var slug = 'titan-in-the-outfield';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">A <span className='titan'>Titan</span><br /> Takes the Field</h1>
            <p className="sub-heading animated fadeInDown delay-1s">Alumna Kelsie Whitmore knew there would be a first woman to play Major League Baseball. She’s on the road to achieving that milestone.</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey / Photos By Matt Gush</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn"></ScrollAnimation>
        
          <p><span className="intro-text">Kelsie Whitmore remembers rainy days.</span> With water dancing on windows and wind howling in the distance, she and her younger brother would create a makeshift baseball diamond in her childhood living room.</p>

          <p>Even when the game was little more than pillow bases and a handful of wiffle balls, Whitmore knew one thing for certain.</p>

          <p>Rain or shine, she was always going to play baseball.</p>

          <h2>First Season as a Ferryhawk</h2>

          <p>Whitmore ’20, ’22 (B.S. kinesiology, M.S. instructional design and technology), a Cal State Fullerton alumna and professional baseball player, took the field in April 2022 as an outfielder and pitcher for the Staten Island FerryHawks. She made history as the first woman to play in the Atlantic League, one of four Major League Baseball partner leagues.</p>

         <figure className='align-wide'>
            <img src={kelsie5} alt="" />
            <figcaption>Cal State Fullerton alumna Kelsie Whitmore took the field as an outfielder and pitcher for the Staten Island FerryHawks in April 2022, making history as the first woman to play in a Major League Baseball partner league.</figcaption>
          </figure>
          
          <p>“I always knew that there was going to be a girl one day, and I thought, if there is one, I want to be that girl who makes it,” says the former Titan softball player.</p>

          <p>After signing with the FerryHawks, Whitmore’s name appeared on jerseys and in national headlines. Sharing her story with publications like the MLB Network, Sports Illustrated and The Athletic, Whitmore’s fan base grew tremendously. It wasn’t long before she was autographing baseballs and interacting with fans in the stands.</p>

          <figure className='align-left large align-outside'>
            <img src={kelsie3} alt="" />
            <figcaption>Kelsie Whitmore (left) said she enjoys interacting with fans and showing young girls that they belong in baseball.</figcaption>
          </figure>
          
          <p>On the field, the two-way sensation played 86 innings in the outfield and pitched 10.1 innings.</p>
          
          <p>“Being able to play with and against former major leaguers like Rusney Castillo from the Red Sox and Julio Teheran from the Tigers — that atmosphere — it’s everything you work for and dream of,” shares Whitmore.</p>

          <p>In the offseason, Whitmore hits the gym and works on her mechanics, hoping to take her game to the next level.</p>

          <p>“When I’m back on the field this season, I want to come back and have people say, ‘This girl worked hard and came back better,’” says Whitmore.</p>

          <blockquote style={{marginTop: '3em', marginBottom: '5em'}}>
            <p>I never saw myself doing anything else.</p>
            <cite>- Kelsie Whitmore ’20, ’22</cite>
          </blockquote>

          <h2>Field of Dreams</h2>

          <figure className='align-wide'>
            <img src={kelsie1} alt="" />
            <figcaption>Kelsie Whitmore ’20, ’22 (B.S. kinesiology, M.S. instructional design and technology)</figcaption>
          </figure>
          
          <p>Before she was a FerryHawk, Whitmore found a supportive community of teammates and coaches at Cal State Fullerton’s Anderson Family Field.</p>

          <p>“College was the time when I really opened up as a person and as a player,” shares Whitmore. “From the start of my college career to when I became a senior, my relationship with my coaches continued to grow. Their support helped me open up about my hopes for the future.”</p>

          <p>Softball head coach Kelly Ford met Whitmore at a recruiting camp in 2017 and was impressed by her drive, athleticism and shining potential.</p>

          <p>“I remember her spending time with our baseball staff,” says Ford. “After that, there wasn’t a year that went by that she didn’t participate in a camp, a USA Baseball event or training.”</p>

          <p>From late nights in the bullpen to networking events, Whitmore seized every opportunity for professional development at CSUF and grew as a leader and teammate.</p>

          <p>“Kelsie welcomed all the freshmen with open arms and guided us,” says Alyssa Hernandez, former Titan softball player and Whitmore’s best friend. “She always put others before herself and set a good example of what it means to be a Titan softball player.”</p>

          <p>Ford adds that Whitmore left behind a legacy of strength, kindness and wisdom when she graduated — a legacy that still exists today.</p>

          <p>“Kelsie changed the standard of how the Titans take the field,” says Ford. “Her teammates and coaches had the utmost respect for her, and she will forever be a part of Titan softball.”</p>

          <figure className="align-wide">
            <Vimeo
              video="819995764"
              autoplay={false}
              responsive={true}
              showPortrait={false}
              showTitle={false}
              showByline={false}
            />
            {/* <figcaption>Alumna Denyz Melchor's GWPAC story</figcaption> */}
          </figure>

          <h2>Baseball Hits Home</h2>

          <p>Whitmore started playing catch with her dad when she was 6 years old. As an energetic kid who loved being outdoors, her childhood revolved around sports. She played soccer, volleyball, golf, and participated in high school track and field, but her heart always went back to baseball.</p>

          <p>“I never saw myself doing anything else,” says Whitmore.</p>

          <figure className='align-right align-outside large'>
            <img src={kelsie2} alt="" />
            <figcaption>Kelsie Whitmore began playing baseball when she was 6 years old.</figcaption>
          </figure>
          
          <p>Before joining the USA Baseball team in 2014, she played Little League and was on Temecula Valley High School’s varsity team. She also played travel baseball, where her dad doubled as her coach for several seasons. With each step, Whitmore knew that she wanted to be a professional baseball player, but she didn’t always say it out loud.</p>

          <p>“People would ask me what I wanted to be when I grew up, and I knew. I knew exactly what I wanted to do, but I stopped telling people because I got shut down a lot,” says Whitmore.</p>

          <p>It took some time, but in the face of criticism, she learned to be unapologetic about her goals by surrounding herself with positivity and a healthy support system.</p>

          <p>Her dream is to sign an MLB contract with the San Diego Padres — the team that she grew up watching.</p>

          <h2>Changing the Game</h2>

          <p>Today, when Whitmore takes the field, she does so with a quiet confidence that eclipses the challenges she has faced along the way.</p>

          <p>“I compared myself to men because they were my competition. I never had a body like them. I never had the build, the strength, the speed or the power, and I was so envious of that,” shares Whitmore. “I overworked myself trying to outperform the competition. What helped me overcome that was learning to love what I was given and who I was.”</p>

          <figure className='align-left large align-outside'>
            <img src={kelsie4} alt="" />
            <figcaption>Outfielder Evelyn Sablan (right) and alumna Kelsie Whitmore (left) became friends while playing on the Titan softball team.</figcaption>
          </figure>
          
          <p>Peyton Toto, a third-year Titan infielder, says that Whitmore’s vulnerability is reshaping the game for young athletes.</p>

          <p>“Her story has inspired a lot of girls to chase their goals. It’s rare that a person would keep going after being told so many times, ‘Women don’t play baseball’ or ‘It’s a men’s sport,’” says Toto.</p>

          <p>Despite these challenges, Whitmore says she’s proud to be opening doors for future female MLB baseball players.</p>

          <p>“What she’s doing for young people, we are going to reap the benefits as a sports community for decades to come,” says Ford. “As far as breaking the glass ceiling, her journey has only just begun.” <span className="end-mark" /></p>

          <p className="learn-more">Support Titan Softball:<br /><a href="https://give.fullerton.edu/softball" target="_blank" rel="noreferrer">give.fullerton.edu/softball</a></p>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 